/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { products } from "../constant/data";

const ProductsCategory = () => {
  const [categories, setCategories] = useState([]);
  const [categoryFilter, setCategoryFilter] = useState("all");
  const [showAllProducts, setShowAllProducts] = useState(false);

  useEffect(() => {
    const uniqueCategories = [
      ...new Set(products.map((product) => product.category)),
    ];
    setCategories(uniqueCategories);
  }, []);

  const filteredProducts =
    categoryFilter === "all"
      ? products
      : products.filter((product) => product.category === categoryFilter);

  const toggleShowAllProducts = () => {
    setShowAllProducts(!showAllProducts);
  };

  return (
    <>
      <div className="feature-area bg-[url('../productsCategoryBg.png')] bg-cover bg-no-repeat bg-center section-padding">
        <div className="container">
          <div className="text-center">
            <div className="mini-title">Support Palestine</div>
            <div className="column-title">
              Boycott Israeli Brands <span className="shape-bg">and</span>{" "}
              Choose Alternatives
            </div>
          </div>
          <div className="pt-10">
            <div className="flex justify-center flex-wrap gap-4 py-4">
              <button
                className={`btn ${
                  categoryFilter === "all" ? "btn-primary" : "btn-outline"
                }`}
                onClick={() => setCategoryFilter("all")}
              >
                All Products
              </button>
              {categories.map((category) => (
                <button
                  key={category}
                  className={`btn ${
                    categoryFilter === category ? "btn-primary" : "btn-outline"
                  }`}
                  onClick={() => setCategoryFilter(category)}
                >
                  {category.charAt(0).toUpperCase() + category.slice(1)}
                </button>
              ))}
            </div>

            <div className="overflow-x-auto w-full">
              <table className="w-full bg-white shadow-lg rounded-lg overflow-hidden">
                <thead className="bg-primary text-white text-sm md:text-lg leading-6">
                  <tr>
                    <th className="py-2 px-2 md:px-4 border-b border-gray-300 small-text-heading">
                      Boycotted
                    </th>
                    <th className="py-2 px-2 md:px-4 border-b border-gray-300 small-text-heading">
                      Pakistani
                    </th>
                    <th className="py-2 px-2 md:px-4 border-b border-gray-300 small-text-heading">
                      International
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {filteredProducts
                    .slice(0, showAllProducts ? filteredProducts.length : 8)
                    .map((product, index) => (
                      <tr
                        key={index}
                        className="text-center bg-gray-100 hover:bg-gray-200 transition-colors duration-200"
                      >
                        <td className="py-1 px-1 md:px-2 border-b border-gray-300 small-text-data">
                          {product.bycotted}
                        </td>
                        <td className="py-1 px-1 md:px-2 border-b border-gray-300 small-text-data">
                          {product.pakistani}
                        </td>
                        <td className="py-1 px-1 md:px-2 border-b border-gray-300 small-text-data">
                          {product.international}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>

            {categoryFilter === "all" && (
              <div className="text-center pt-10">
                <button
                  className="btn btn-primary"
                  onClick={toggleShowAllProducts}
                >
                  {showAllProducts
                    ? "Show Less Products"
                    : "View More Products"}
                </button>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="relative z-[1] mx-auto my-4 max-w-[1170px] rounded-md overflow-hidden bg-[url('../images/section-bg-1.png')] bg-cover bg-center bg-no-repeat p-6 md:p-12 lg:p-20 text-black shadow-lg">
        <div className="p-6 md:p-12">
          <div className="flex items-center justify-center h-full">
            <div className="max-w-[800px] text-center md:text-left">
              <div className="text-2xl md:text-3xl lg:text-5xl xl:text-7xl font-bold leading-[1.5]">
                Have a question or suggestion about products?
              </div>
              <div className="mt-6">
                <Link to="/contacts">
                  <button className="btn btn-primary text-lg md:text-xl font-bold hover:bg-gray-200 transition-colors duration-200">
                    Contact Now
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductsCategory;
