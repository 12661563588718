/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { causesData } from "../constant/data";

const Causes = () => {
  return (
    <section className="section-padding">
      <div className="container">
        <header className="text-center mb-10">
          <h2 className="mini-title">Support Palestine Today</h2>
          <h3 className="column-title">
            Popular <span className="shape-bg">Causes</span>
          </h3>
        </header>
        <div className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-6">
          {causesData.map((data) => (
            <article
              key={data.id}
              className="cause-item rounded-md overflow-hidden shadow-box8 hover:shadow-box8 transition duration-200 ease-in-out bg-[#FFE8E8] text-center flex flex-col justify-center items-center p-4 hover:-translate-y-2"
            >
              <div className="icon-container w-[72px] h-[72px] rounded-full bg-white relative mx-auto flex flex-col justify-center items-center mb-8 group-hover:bg-[#FFE8E8]">
                <img
                  src={data.logo}
                  alt={data.title}
                  className="icon w-[32px] h-[32px] object-cover"
                />
              </div>
              <div className="cause-content">
                <h4 className="text-xl mb-2 font-semibold">{data.title}</h4>
                <p className="text-sm text-gray-400">{data.description}</p>
              </div>
            </article>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Causes;
