import React, { useState } from "react";
import { Disclosure } from "@headlessui/react";
import { faq } from "../constant/images";
import { accordionData } from "../constant/data";

const Accordion = () => {
  const [openIndex, setOpenIndex] = useState(null);

  const handleDisclosureClick = (index) => {
    setOpenIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  return (
    <div className="section-padding bg-white bg-[url('../images/section-bg-15.png')] bg-bottom bg-cover bg-no-repeat">
      <div className="container">
        <div className="grid lg:grid-cols-2 grid-cols-1 gap-[30px]">
          <div>
            <div className="mini-title">Frequently Asked Questions</div>
            <div className="column-title">
              General <span className="shape-bg">Questions</span>
            </div>
            <ul className="list accrodains space-y-[30px] lg:max-w-[470px]">
              {accordionData.map((faqItem, index) => (
                <Disclosure key={faqItem.id}>
                  {({ open }) => {
                    const isOpen = open || openIndex === index;
                    return (
                      <li className={isOpen ? "open" : null}>
                        <Disclosure.Button
                          type="button"
                          className="accrodain-button"
                          onClick={() => handleDisclosureClick(index)}
                        >
                          <span>{faqItem.question}</span>
                          <span className="icon-pm"></span>
                        </Disclosure.Button>

                        <Disclosure.Panel className="content">
                          {faqItem.answer}
                        </Disclosure.Panel>
                      </li>
                    );
                  }}
                </Disclosure>
              ))}
            </ul>
          </div>
          <div>
            <img src={faq} alt="Frequently Asked Questions" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Accordion;
