import React from "react";
import { Header, Footer, PageBanner, BlogsGrid } from "../Components";

const Blogs = () => {
  return (
    <>
      <Header />
      <PageBanner title={"Blogs"} pageName="Blogs" />
      <BlogsGrid />
      <Footer />
    </>
  );
};

export default Blogs;
