/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { testimonialImg } from "../constant/images";
import { Link } from "react-router-dom";
import { testimonialInfo } from "../constant/data";

const Testimonials = () => {
  return (
    <div className="section-padding bg-[url('../images/section-bg-16.png')] bg-cover bg-no-repeat lg:mt-[136px]">
      <div className="container lg:-mt-[250px] xl:pb-[136px] lg:pb-20 pb-10">
        <div
          className="grid xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-[30px] bg-white shadow-box14 rounded-md
                divide-x-2 divide-[#E4EEED] p-10"
        >
          {testimonialInfo.map((data) => {
            return (
              <div className="text-center" key={data.id}>
                <h2 className="text-secondary font-bold">
                  <span className="counter">{data.number}</span>+
                </h2>
                <span className="block text-black font-semibold">
                  {data.text}
                </span>
              </div>
            );
          })}
        </div>
      </div>
      <div className="container">
        <div className="grid grid-cols-12 lg:gap-10 gap-6">
          <div className="xl:col-span-7 lg:col-span-6 col-span-12">
            <img src={testimonialImg} alt="Testimonial" draggable="false" />
          </div>
          <div className="xl:col-span-5 lg:col-span-6 col-span-12 flex flex-col justify-center">
            <div className="mini-title">Testimonials</div>
            <h4 className="column-title">
              What Our <span className="shape-bg">Supporters</span> Say About Us
            </h4>
            <div>
              Our community members and volunteers share their experiences and
              the impact of our efforts in supporting the Palestinian cause.
              Read their stories and join us in making a difference.
            </div>
            <div className="mt-12">
              <Link to="/about" className="btn btn-primary">
                Explore More
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Testimonials;
