import React from "react";
import { productsHeroImg } from "../constant/images";
import { productsProgressBar } from "../constant/data";

const ProductsHero = () => {
  return (
    <div className="about-area section-padding">
      <div className="container">
        <div className="grid grid-cols-12 gap-[30px] items-center">
          <div className="xl:col-span-7 lg:col-span-6 col-span-12">
            <img
              src={productsHeroImg}
              alt="About Build Khilafat"
              className="block w-full rounded-lg"
            />
          </div>
          <div className="xl:col-span-5 lg:col-span-6 col-span-12">
            <div className="mini-title">Boycott Israeli Brands (Products)</div>
            <h4 className="column-title">Supporting Ethical Consumption</h4>
            <div className="mb-8">
              Boycotting Israeli products is a crucial step in supporting
              Palestinian rights and advocating for justice.
            </div>
            <div className="space-y-8">
              {productsProgressBar.map((data) => {
                return (
                  <div className="progressbar-group" key={data.id}>
                    <div className="flex justify-between">
                      <span className="block text-black font-semibold mb-2">
                        {data.label}
                      </span>
                      <span className="block mb-2 text-black font-semibold">
                        {data.percentage}%
                      </span>
                    </div>
                    <div className="rounded-[2px] overflow-hidden bg-opacity-10 bg-black h-[6px] relative">
                      <div
                        className={`ani h-[6px] ${data.colorClass} block absolute left-0 top-1/2 -translate-y-1/2`}
                        style={{ width: `${data.percentage}%` }}
                      ></div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductsHero;
