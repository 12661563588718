/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

const Trusted = ({ section_padding_bottom, section_padding_top }) => {
  return (
    <div
      className={`brands-area ${section_padding_bottom} ${section_padding_top}`}
    >
      <div className="container text-center">
        <div className="text-black text-2xl font-medium mb-[50px]">
          <span className="shape-bg mini">Trusted</span> By Over 100+ People
        </div>
        <p>
          The Prophet Muhammad (ﷺ) said, "Whoever saves a life, it is as if they
          have saved the whole of humanity. And whoever does not save a life, it
          is as if they have killed all of humanity, and Allah is displeased
          with them."
        </p>
      </div>
    </div>
  );
};

export default Trusted;
