import React from "react";
import {
  Header,
  Footer,
  AboutHero,
  Membership,
  Video,
  NewsLetter,
  PageBanner,
  Team,
} from "../Components";

const About = () => {
  return (
    <>
      <Header />
      <PageBanner title={"About"} pageTitle="About" />
      <AboutHero />
      <Membership />
      <Video />
      <Team />
      <NewsLetter />
      <Footer />
    </>
  );
};

export default About;
