/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { loadingIcon } from "../constant/images";
import { blogsData } from "../constant/data";

const HomeBlogs = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [latestBlogs, setLatestBlogs] = useState([]);

  useEffect(() => {
    setTimeout(() => {
      const latest = blogsData.filter((blog) => blog.isLatest);

      setLatestBlogs(latest);
      setIsLoading(false);
    }, 800);
  }, []);

  return (
    <div className="section-padding bg-[url('../images/all-img/section-bg-5.png')] bg-cover bg-no-repeat bg-center">
      <div className="container home-hero">
        <div className="lg:flex justify-between items-center">
          <div className="mb-3">
            <div className="mini-title">Support Palestine</div>
            <h2 className="column-title">
              Read <span className="shape-bg">Blogs</span>
            </h2>
          </div>
          <div className="mb-3">
            <Link to="/blogs" className="btn btn-primary">
              View All
            </Link>
          </div>
        </div>
        {isLoading ? (
          <div className="flex justify-center items-center h-64">
            <img src={loadingIcon} alt="Loading..." className="w-12 h-12" />
          </div>
        ) : (
          <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-[30px] pt-7">
            {latestBlogs.map((blog) => (
              <div
                key={blog.id}
                className="bg-white shadow-box5 rounded-[8px] transition duration-100 hover:shadow-box3"
              >
                <div className="course-thumb h-[260px] rounded-t-[8px] relative">
                  <img
                    src={blog.img1}
                    alt={blog.title}
                    className="w-full h-full object-cover rounded-t-[8px]"
                  />
                </div>
                <div className="course-content p-8">
                  <h4 className="text-xl mb-5 font-bold">
                    <Link
                      to={`/blogs/${blog.id}`}
                      rel="noopener noreferrer"
                      className="hover:text-primary transition duration-150"
                    >
                      {blog.title}
                    </Link>
                  </h4>
                  <Link
                    to={`/blogs/${blog.id}`}
                    rel="noopener noreferrer"
                    className="text-black font-semibold hover:underline transition duration-150"
                  >
                    Read More
                  </Link>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default HomeBlogs;
