import React, { useEffect } from "react";
import {
  Header,
  Footer,
  ProductsHero,
  ProductsCategory,
  PageBanner,
} from "../Components";

const Products = () => {
  useEffect(() => {
    document.documentElement.scrollTop = 0;
  }, []);
  return (
    <section className="font-gilroy font-medium text-gray text-lg leading-[27px]">
      <Header />
      <PageBanner title={"Products"} pageTitle="Products" />
      <ProductsHero />
      <ProductsCategory />
      <Footer />
    </section>
  );
};

export default Products;
