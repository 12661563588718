import React, { useState } from "react";
import emailjs from "@emailjs/browser";
import { mailWhite, bigMail } from "../constant/images";

const NewsLetter = () => {
  const [subscriberEmail, setSubscriberEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleSubscribe = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const templateParams = {
        email: subscriberEmail,
      };

      const response = await emailjs.send(
        "service_jabfegh",
        "template_je7rb5h",
        templateParams,
        "zpFecgHhGbTZ3i7bo"
      );

      console.log("SUCCESS!", response.status, response.text);
      setSubscriberEmail("");
      setSuccessMessage("Subscribed successfully!");
      setTimeout(() => {
        setSuccessMessage("");
      }, 3000);
    } catch (error) {
      console.error("FAILED...", error);
      setErrorMessage("Failed to subscribe. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="bg-[url('../images/section-bg-3.png')] bg-cover bg-no-repeat bg-center py-20">
      <div className="container">
        <div className="lg:flex lg:items-center lg:space-x-[70px]">
          <div className="flex-none">
            <div className="flex items-center space-x-8 text-white max-w-[327px] mx-auto lg:mb-0 md:mb-10 mb-5">
              <div className="flex-none">
                <img src={bigMail} alt="" />
              </div>
              <div className="flex-1 lg:text-[33px] lg:leading-[51px] md:text-3xl text-2xl font-bold">
                Subscribe to Our Newsletter
              </div>
            </div>
          </div>
          <div className="flex-1">
            <form
              onSubmit={handleSubscribe}
              className="md:flex items-center relative md:space-x-6 md:space-y-0 space-y-4"
            >
              <div className="flex-1 relative">
                <span className="absolute left-0 top-1/2 -translate-y-1/2">
                  <img src={mailWhite} alt="" />
                </span>
                <input
                  type="email"
                  placeholder={successMessage || "Enter your mail address"}
                  className="border-b border-t-0 border-l-0 border-r-0 border-[#B2E2DF] ring-0 focus:ring-0 bg-transparent text-white placeholder:text-[#B2E2DF] focus:outline-0 focus:border-[#B2E2DF] transition pl-8 text-lg"
                  value={subscriberEmail}
                  onChange={(e) => setSubscriberEmail(e.target.value)}
                  required
                />
              </div>
              <div className="flex-none">
                <button
                  type="submit"
                  className="btn bg-white text-secondary md:w-auto w-full"
                  disabled={loading}
                >
                  {loading ? "Subscribing..." : "Subscribed Now"}
                </button>
              </div>
            </form>
            {errorMessage && (
              <p className="text-red-600 text-sm mt-2">{errorMessage}</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewsLetter;
