import React, { useRef, useState } from "react";
import { video } from "../constant/images";
import Trusted from "./Trusted";

const Video = () => {
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);

  const handlePlayPause = () => {
    if (videoRef.current.paused) {
      videoRef.current.play();
      setIsPlaying(true);
    } else {
      videoRef.current.pause();
      setIsPlaying(false);
    }
  };

  const handleFullscreen = () => {
    if (videoRef.current.requestFullscreen) {
      videoRef.current.requestFullscreen();
    } else if (videoRef.current.mozRequestFullScreen) {
      videoRef.current.mozRequestFullScreen();
    } else if (videoRef.current.webkitRequestFullscreen) {
      videoRef.current.webkitRequestFullscreen();
    } else if (videoRef.current.msRequestFullscreen) {
      videoRef.current.msRequestFullscreen();
    }
  };

  const videoEnded = () => {
    setIsPlaying(false);
  };

  return (
    <div className="relative video-area section-padding-bottom z-10">
      <div className="bg-[url('../images/all-img/section-bg-7.png')] bg-cover bg-no-repeat bg-center absolute left-0 bottom-0 h-[60%] w-full z-[-1]"></div>
      <div className="container">
        <div className="video-wrapper xl:mb-[205px] lg:mb-[150px] mb-20">
          <div className="xl:h-[652px] lg:h-[500px] md:h-[400px] sm:h-[300px] h-[250px] max-w-[1112px] mx-auto p-6 bg-black shadow-box8 rounded-lg relative">
            <video
              ref={videoRef}
              className="object-cover w-full h-full block"
              src={video}
              controls={false}
              onEnded={videoEnded}
            ></video>
            <div
              className="h-[80px] w-[80px] mx-auto absolute left-1/2 top-1/2 -translate-y-1/2 -translate-x-1/2 flex flex-col justify-center items-center cursor-pointer"
              onClick={handlePlayPause}
            >
              <div className="animate-ping absolute inline-flex h-full w-full rounded-full ring-white ring-2"></div>
              <button className="bg-white w-20 h-20 sm:w-10 sm:h-10 flex justify-center items-center rounded-full text-3xl text-primary">
                <iconify-icon
                  icon={isPlaying ? "bi:pause-fill" : "bi:play-fill"}
                ></iconify-icon>
              </button>
            </div>
            <button
              className="absolute bottom-2 sm:bottom-4 left-1/2 transform -translate-x-1/2 bg-white bg-opacity-70 text-gray-800 p-2 sm:p-3 rounded-full transition transform hover:scale-110 hover:bg-opacity-80 shadow-lg z-10"
              onClick={handleFullscreen}
              style={{ width: "40px", height: "40px" }}
            >
              <iconify-icon
                icon="mdi:fullscreen"
                style={{ fontSize: "20px" }}
              ></iconify-icon>
            </button>
          </div>
        </div>
      </div>
      <Trusted />
    </div>
  );
};

export default Video;
