/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { ok, helpImg } from "../constant/images";
import { helpData } from "../constant/data";

const HowToHelp = () => {
  return (
    <section className="bg-[url('../images/helpBg.png')] bg-cover bg-no-repeat bg-center section-padding">
      <div className="container">
        <div className="lg:grid grid-cols-12 gap-8">
          <div className="xl:col-span-5 lg:col-span-6 col-span-12">
            <div className="mini-title">How Can You Help?</div>
            <h4 className="column-title">
              Stand For <span className="shape-bg">Peace</span>
            </h4>
            <p>
              The Prophet Muhammad (ﷺ) said, "The best of people are those that
              bring most benefit to the rest of mankind."
              <br /> <br /> Support our mission to bring peace and justice to
              Palestine.
              <br /> <br /> You can help them by:
            </p>
            <ul className="space-y-4 pt-8">
              {helpData.map((data) => {
                return (
                  <li className="flex items-center" key={data.id}>
                    <img src={ok} alt={data.alt} className="flex-none mr-3" />
                    <h4 className="text-lg font-semibold">{data.text}</h4>
                  </li>
                );
              })}
            </ul>
          </div>
          <div className="xl:col-span-7 lg:col-span-6 col-span-12 lg:pt-0 md:pt-10 pt-6">
            <img src={helpImg} alt="How to Help" className="mx-auto w-full" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default HowToHelp;
