import { BrowserRouter, Route, Routes } from "react-router-dom";

import {
  Home,
  About,
  Products,
  Blogs,
  ContactUs,
  Thanks,
  ErrorPage,
} from "./Pages";
import { SingleBlog } from "./Components";

function App() {
  return (
    <BrowserRouter>
      <div className="font-gilroy font-medium text-gray text-lg leading-[27px]">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/products" element={<Products />} />
          <Route path="/blogs" element={<Blogs />} />
          <Route path="/blogs/:slug" element={<SingleBlog />} />
          <Route path="/contacts" element={<ContactUs />} />
          <Route path="/thanks" element={<Thanks />} />
          <Route path="*" element={<ErrorPage />} />
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
