import React, { useState } from "react";
import emailjs from "@emailjs/browser";
import { footerLogo, mailIcon } from "../constant/images";
import { footerLinks } from "../constant/data";
import { Link } from "react-router-dom";

const Footer = () => {
  const [subscriberEmail, setSubscriberEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  const handleSubscribe = (e) => {
    e.preventDefault();
    setLoading(true);

    const templateParams = {
      email: subscriberEmail,
    };

    emailjs
      .send(
        "service_jabfegh",
        "template_je7rb5h",
        templateParams,
        "zpFecgHhGbTZ3i7bo"
      )
      .then(
        (response) => {
          console.log("SUCCESS!", response.status, response.text);
          setSubscriberEmail("");
          setSuccessMessage("Subscribed successfully!");
          setLoading(false);
          setTimeout(() => {
            setSuccessMessage("");
          }, 3000);
        },
        (err) => {
          console.log("FAILED...", err);
          setLoading(false);
        }
      );
  };

  return (
    <footer className="bg-black bg-[url('../images/footer-bg-1.png')] bg-cover bg-center bg-no-repeat">
      <div className="section-padding container">
        <div className="grid grid-cols-1 gap-7 md:grid-cols-2 lg:grid-cols-3">
          <div className="single-footer">
            <div className="lg:max-w-[270px]">
              <a href="/" className="mb-10 block">
                <img src={footerLogo} alt="Build Khalafat" />
              </a>
              <p>
                Together, we stand for peace, justice, and unwavering
                solidarity.
              </p>
              {/* <ul className="flex space-x-4 pt-8">
                {socialLinks.map((data) => {
                  return (
                    <li key={data.id}>
                      <a
                        href={data.url}
                        target="_blank"
                        rel="noreferrer noopener"
                        className="flex h-12 w-12 flex-col items-center justify-center rounded bg-white bg-opacity-[0.08] text-2xl text-white transition hover:bg-primary hover:text-white"
                      >
                        <iconify-icon icon={data.icon}></iconify-icon>
                      </a>
                    </li>
                  );
                })}
              </ul> */}
            </div>
          </div>
          <div className="single-footer footer-grid2">
            <div className="flex space-x-[80px]">
              <div className="flex-1 lg:flex-none">
                <h4 className="mb-8 text-2xl font-bold text-white">Links</h4>
                <ul className="list-item space-y-5">
                  {footerLinks.map((data) => {
                    return (
                      <li className="hover:text-primary" key={data.id}>
                        <Link to={data.url}>{data.text}</Link>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>
          <div className="single-footer">
            <h4 className="mb-8 text-2xl font-bold text-white">Newsletter</h4>
            <div className="mb-8">
              Join our <span className="text-primary underline">community</span>{" "}
              of supporters receiving updates on peace and justice for
              Palestine.
            </div>
            <form onSubmit={handleSubscribe}>
              <div className="mb-4 flex items-center rounded-md bg-white py-[10px] pr-[10px] pl-6 shadow-e1">
                <div className="flex-none">
                  <span className=" ">
                    <img src={mailIcon} alt="Mail Icon" />
                  </span>
                </div>
                <div className="flex-1">
                  <input
                    type="email"
                    placeholder={successMessage || "Enter your email"}
                    className="border-none focus:ring-0"
                    value={subscriberEmail}
                    onChange={(e) => setSubscriberEmail(e.target.value)}
                    required
                  />
                </div>
              </div>
              <button
                type="submit"
                className="btn btn-primary block w-full text-center"
              >
                {loading ? "Subscribing..." : "Subscribe Now"}
              </button>
            </form>
          </div>
        </div>
      </div>
      <div className="container border-t border-white border-opacity-[0.1] py-8 text-center text-base">
        Made with ❤️ By Team{" "}
        <a href="https://www.linkedin.com/company/logiks-dev/">Logiks Dev</a>
      </div>
    </footer>
  );
};

export default Footer;
