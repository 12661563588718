import React from "react";
import { Header, Footer, PageBanner, ContactForm } from "../Components";
import { contactUsData } from "../constant/data";

const ContactUs = () => {
  return (
    <>
      <Header />
      <PageBanner title={"Contacts"} pageName="Contacts" />
      <div class="nav-tab-wrapper tabs  section-padding">
        <div class="container">
          <div class=" grid grid-cols-12 gap-[30px]">
            <div class="xl:col-span-5 lg:col-span-6 col-span-12 ">
              <div class="mini-title">Contact Us</div>
              <h4 class="column-title ">
                Get In Touch <span class="shape-bg">Now</span>
              </h4>
              <div>
                Join us in our mission for peace and justice. Send your message
                or discuss at Build Khalafat.
              </div>
              <ul class=" list-item space-y-6 pt-8">
                {contactUsData.map((data) => {
                  return (
                    <li class="flex" key={data.id}>
                      <div class="flex-none mr-6">
                        <div>
                          <img
                            src={data.img}
                            alt={data.label.slice(0, data.label.length - 1)}
                          />
                        </div>
                      </div>
                      <div class="flex-1">
                        <h4 class=" lg:text-xl text-lg mb-1">{data.label}</h4>
                        <div>{data.text}</div>
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>
            <div class="xl:col-span-7 lg:col-span-6 col-span-12">
              <div class="bg-white shadow-box7 p-8 rounded-md">
                <ContactForm />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ContactUs;
