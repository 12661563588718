import React, { useEffect, useState } from "react";
import {
  fbIcon,
  pnIcon,
  twIcon,
  insIcon,
  loadingIcon,
  blogImg2,
  blogImg3,
} from "../constant/images";
import { Header, Footer, PageBanner } from "../Components";
import { blogsData } from "../constant/data";
import { useParams, Link } from "react-router-dom";

const SinglePost = () => {
  const [blog, setBlog] = useState({});
  const [prevBlog, setPrevBlog] = useState(null);
  const [nextBlog, setNextBlog] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  let { slug: id } = useParams();
  id = parseInt(id);

  useEffect(() => {
    const fetchBlogData = () => {
      const filteredBlog = blogsData.find((item) => item.id === id);
      if (filteredBlog) {
        setIsLoading(true);
        setTimeout(() => {
          const currentIndex = blogsData.findIndex((item) => item.id === id);
          const prevBlog =
            currentIndex > 0
              ? blogsData[currentIndex - 1]
              : blogsData[blogsData.length - 1];
          const nextBlog =
            currentIndex < blogsData.length - 1
              ? blogsData[currentIndex + 1]
              : blogsData[0];

          setBlog(filteredBlog);
          setPrevBlog(prevBlog);
          setNextBlog(nextBlog);
          setIsLoading(false);
        }, 800);
      } else {
        setBlog({});
        setPrevBlog(null);
        setNextBlog(null);
        setIsLoading(false);
      }
    };

    fetchBlogData();
  }, [id]);

  const customizeTitle = (title) => {
    if (title.length > 36) return title.substr(0, 36) + "...";
    return title;
  };

  const handleLoading = () => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 1500);
  };

  const shareUrl = window.location.href;
  const shareText = blog.title || "Check out this blog post";

  if (isLoading) {
    return (
      <>
        <Header />
        <PageBanner pageName={"Blog Detail"} title={"Blog Detail"} />
        <div className="flex justify-center items-center single-blog-loading">
          <img src={loadingIcon} alt="Loading..." className="w-12 h-12" />
        </div>
        <Footer />
      </>
    );
  }

  return (
    <>
      <Header />
      <PageBanner pageName={"Blog Detail"} title={"Blog Detail"} />
      <div className="section-padding flex justify-center items-center single-blog">
        <div className="container single-blog-container">
          <div className="bg-[#F8F8F8] rounded-md">
            <img src={blog.img1} alt="" className="rounded-t-md mb-10" />
            <div className="pb-10 single-blog-padding">
              <h3>{blog.title}</h3>
              <p className="mt-4">{blog.description1}</p>
              <p className="mt-4">{blog.description2}</p>
              <div className="grid md:grid-cols-2 grid-cols-1 gap-[30px] my-8">
                <img src={blogImg2} alt="" />
                <img src={blogImg3} alt="" />
              </div>

              <p className="mt-4">{blog.description3}</p>
              <ul className="list-disc marker:text-secondary space-y-4 mt-6">
                {blog.data &&
                  blog.data.map((item) => <li key={item.id}>{item.text}</li>)}
              </ul>

              <div className="grid xl:grid-cols-2 grid-cols-1 gap-5 md:mt-14 mt-8">
                <ul className="flex space-x-3 items-center">
                  <li className="text-black font-semibold">Share On:</li>
                  <li>
                    <a
                      href={`https://www.facebook.com/sharer/sharer.php?u=${shareUrl}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="flex h-8 w-8"
                    >
                      <img src={fbIcon} alt="Share on Facebook" />
                    </a>
                  </li>
                  <li>
                    <a
                      href={`https://pinterest.com/pin/create/button/?url=${shareUrl}&description=${shareText}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="flex h-8 w-8"
                    >
                      <img src={pnIcon} alt="Share on Pinterest" />
                    </a>
                  </li>
                  <li>
                    <a
                      href={`https://twitter.com/intent/tweet?url=${shareUrl}&text=${shareText}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="flex h-8 w-8"
                    >
                      <img src={twIcon} alt="Share on Twitter" />
                    </a>
                  </li>
                  <li>
                    <a
                      href={`https://www.instagram.com/?url=${shareUrl}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="flex h-8 w-8"
                    >
                      <img src={insIcon} alt="Share on Instagram" />
                    </a>
                  </li>
                </ul>
              </div>

              <div className="grid xl:grid-cols-2 grid-cols-1 gap-[30px] md:mt-14 mt-8">
                {prevBlog && (
                  <Link
                    className="flex space-x-4 shadow-box7 rounded p-5 bg-white"
                    to={`/blogs/${prevBlog.id}`}
                    onClick={handleLoading} // Remove () to pass reference
                  >
                    <div className="flex-none">
                      <div className="h-20 w-20 rounded">
                        <img
                          src={prevBlog.img1}
                          alt=""
                          className="w-full h-full object-cover rounded"
                        />
                      </div>
                    </div>
                    <div className="flex-1">
                      <span className="text-primary text-base mb-1">
                        Prev Post
                      </span>
                      <div className="mb-1 font-semibold text-black">
                        {customizeTitle(prevBlog.title)}
                      </div>
                    </div>
                  </Link>
                )}

                {nextBlog && (
                  <Link
                    className="flex flex-row-reverse shadow-box7 bg-white rounded p-5"
                    to={`/blogs/${nextBlog.id}`}
                    onClick={handleLoading}
                  >
                    <div className="flex-none">
                      <div className="h-20 w-20 rounded ml-4">
                        <img
                          src={nextBlog.img1}
                          alt=""
                          className="w-full h-full object-cover rounded"
                        />
                      </div>
                    </div>
                    <div className="flex-1 text-right">
                      <span className="text-primary text-base mb-1">
                        Next Post
                      </span>
                      <div className="mb-1 font-semibold text-black">
                        {customizeTitle(nextBlog.title)}
                      </div>
                    </div>
                  </Link>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default SinglePost;
