/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Link } from "react-router-dom";

const SupportLinks = () => {
  return (
    <section className="section-padding bg-[url('../images/section-bg-9.png')] bg-no-repeat bg-center bg-cover">
      <div className="container">
        <div className="text-center mb-8">
          <div className="mini-title">Support the People of Palestine</div>
          <h2 className="column-title">
            Donate and Boycott Products <span className="shape-bg">Today</span>
          </h2>
        </div>

        <div className="space-y-8">
          <div className="bg-white p-6 rounded-lg text-center">
            <p className="text-lg">
              The Prophet Muhammad (ﷺ) said, "The believer's shade on the Day of
              Resurrection will be his charity."
            </p>
            <div className="flex justify-center mt-4">
              <a
                href="https://alkhidmat.org/donate"
                target="_blank"
                rel="noopener noreferrer"
                className="btn btn-primary"
              >
                Donate Now
              </a>
            </div>
          </div>

          <div className="bg-white p-6 rounded-lg text-center">
            <p className="text-lg">
              The Prophet Muhammad (ﷺ) said, "Whoever amongst you sees an evil,
              let him change it with his hand; if he is unable to do so, then
              with his tongue; and if he is unable to do so, then with his heart
              — and that is the weakest of faith."
            </p>
            <div className="flex justify-center mt-4">
              <Link to="/products" className="btn btn-primary">
                View Alternative Products
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SupportLinks;
