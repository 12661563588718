/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { aboutHero } from "../constant/images";
import { aboutHeroData } from "../constant/data";

const AboutHero = () => {
  return (
    <div className="about-area section-padding">
      <div className="container">
        <div className="grid lg:grid-cols-2 grid-cols-1 gap-10 items-center">
          <div className="text-center lg:text-left">
            <img
              src={aboutHero}
              alt="About Build Khalafat"
              className="mx-auto lg:mx-0"
            />
          </div>
          <div>
            <div className="mini-title">About Us</div>
            <h4 className="column-title">
              Discover <span className="shape-bg">Build Khalafat</span>
            </h4>
            <p className="mb-6">
              Build Khalafat is dedicated to amplifying the voices of Muslims,
              particularly in Palestine, through steadfast support and advocacy.
            </p>
            <ul className="list-item space-y-6">
              {aboutHeroData.map((data) => {
                return (
                  <li className="flex items-center" key={data.id}>
                    <div className="flex-none mr-6">
                      <div className="h-[72px] w-[72px] rounded-full">
                        <img
                          src={data.img}
                          alt="Our Mission"
                          className="w-full h-full object-cover rounded-full block"
                        />
                      </div>
                    </div>
                    <div>
                      <h4 className="text-xl mb-1">{data.title}</h4>
                      <p>{data.description}</p>
                    </div>
                  </li>
                );
              })}
            </ul>
            <div className="pt-6 lg:pt-8">
              <a
                href="https://alkhidmat.org/donate"
                target="_blank"
                rel="noopener noreferrer"
                className="btn btn-primary"
              >
                Donate Now
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutHero;
