import React from "react";
import { homeHeroImg } from "../constant/images";

const HomeHero = () => {
  return (
    <section className="bg-[url('../images/homeBanner.png')] bg-cover bg-no-repeat bg-center overflow-hidden home-hero">
      <div className="container flex flex-col md:flex-row items-center md:space-x-8 home-hero-container">
        <div className="max-w-[570px] xl:pt-28 md:pt-20 md:pb-20 pt-28 pb-14 xl:pb-40 space-y-8 hero-margin">
          <h1>
            Support{" "}
            <span className="text-secondary inline-block bg-[url('../images/banner/shape.svg')] bg-no-repeat bg-bottom">
              Palestine
            </span>{" "}
            Through Your Contributions
          </h1>
          <div className="plain-text text-gray leading-[30px]">
            Every donation, no matter how small, makes a significant difference.
            Join us in making an impact and supporting those in need.
          </div>
        </div>
        <div className="hidden md:block md:flex-shrink-0">
          <img src={homeHeroImg} alt="Free Palestine" />
        </div>
      </div>
    </section>
  );
};

export default HomeHero;
