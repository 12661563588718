import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { logo } from "../constant/images";
import MobileMenu from "./MobileMenu";
import { navData } from "../constant/data";

const Header = () => {
  const [activeMobileMenu, setActiveMobileMenu] = useState(false);
  const scrollNav = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      if (scrollNav.current) {
        const windowScroll = window.scrollY > 100;
        scrollNav.current.classList.toggle("rt-sticky-active", windowScroll);
        scrollNav.current.classList.toggle("sticky", windowScroll);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <header
        className="site-header home-one-header top-0 w-full z-[999] rt-sticky"
        ref={scrollNav}
      >
        <div className="main-header py-6">
          <div className="container">
            <div className="flex items-center justify-between">
              <Link
                to="/"
                className="brand-logo flex-none lg:mr-10  header-logo"
              >
                <img src={logo} alt="logo" />
              </Link>
              <div className="flex items-center flex-1">
                <div className="flex-1 main-menu relative mr-[74px]">
                  <ul className="menu-active-classes">
                    {navData.map((data) => (
                      <li key={data.id}>
                        <Link to={data.url}>{data.text}</Link>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="flex-none flex space-x-[18px]">
                  <div className="hidden lg:block">
                    <Link
                      to="/contacts"
                      className="btn btn-primary py-[15px] px-8"
                    >
                      Contacts
                    </Link>
                  </div>
                  <div className="block lg:hidden">
                    <button
                      type="button"
                      className="text-3xl md:w-[56px] h-10 w-10 md:h-[56px] rounded bg-[#F8F8F8] flex flex-col items-center justify-center menu-click"
                      onClick={() => setActiveMobileMenu(!activeMobileMenu)}
                    >
                      <iconify-icon
                        icon="cil:hamburger-menu"
                        rotate="180deg"
                      ></iconify-icon>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      {activeMobileMenu && (
        <MobileMenu
          activeMenu={activeMobileMenu}
          setActiveMenu={setActiveMobileMenu}
        />
      )}
    </>
  );
};

export default Header;
