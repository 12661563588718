/* eslint-disable no-unused-expressions */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { logo } from "../constant/images";
import { mobileMenuData } from "../constant/data";

const MobileMenu = ({ activeMenu, setActiveMenu }) => {
  const [showHomeMenu, setShowHomeMenu] = useState(false);

  const handleShowHomeMenu = () => {
    setShowHomeMenu(!showHomeMenu);
  };

  return (
    <>
      <div className="openmobile-menu fixed top-0 h-screen pt-10 pb-6 bg-white shadow-box2 w-[320px] overflow-y-auto flex flex-col z-[999] active-mobile-menu">
        <div className="flex justify-between px-6 flex-none">
          <Link to={"/home"} className="brand-logo flex-none mr-10 ">
            <img src={logo} alt="logo" />
          </Link>
          <span
            className=" text-3xl text-black cursor-pointer rt-mobile-menu-close"
            onClick={() => {
              setActiveMenu(!activeMenu);
            }}
          >
            <iconify-icon icon="fe:close"></iconify-icon>
          </span>
        </div>
        <div className="mobile-menu mt-6 flex-1 ">
          <ul className="menu-active-classes">
            {mobileMenuData.map((data) => {
              return (
                <li onClick={handleShowHomeMenu} key={data.id}>
                  <Link to={data.url}>{data.text}</Link>
                </li>
              );
            })}
          </ul>
        </div>
        {/* <div className=" flex-none pb-4">
          <div className=" text-center text-black font-semibold mb-2">
            Follow Us
          </div>
          <ul className="flex space-x-4 justify-center ">
            {mobileMenuFooterData.map((data) => {
              return (
                <li key={data.id}>
                  <a
                    href={data.url}
                    target="_blank"
                    rel="noreferrer noopener"
                    className="flex h-10 w-10"
                  >
                    <img src={data.icon} alt="fbIcon" />
                  </a>
                </li>
              );
            })}
          </ul>
        </div> */}
      </div>{" "}
      <div className={`rt-mobile-menu-overlay ${activeMenu && "active"}`}></div>
    </>
  );
};

export default MobileMenu;
