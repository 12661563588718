import React from "react";
import { Link } from "react-router-dom";

const Membership = () => {
  return (
    <div className="lg:pt-10 section-padding-bottom ">
      <div className="container">
        <div className="grid lg:grid-cols-2 grid-cols-1 gap-7">
          <div className="bg-[url('../images/membershipBg1.png')] bg-cover bg-no-repeat p-10 rounded-md">
            <div className="max-w-[337px]">
              <div className="mini-title">Join as a Volunteer</div>
              <div className="text-[34px] text-black leading-[51px]">
                Support <span className="shape-bg">Palestine</span>
              </div>
              <div className="mt-6 mb-12">
                Empower communities with lasting impact.
              </div>
              <Link to="/contacts" className="btn btn-primary">
                Apply Now
              </Link>
            </div>
          </div>

          <div className="bg-[url('../images/membershipBg2.png')] bg-cover bg-no-repeat p-10 rounded-md">
            <div className="max-w-[337px]">
              <div className="mini-title">Product Inquiries</div>
              <div className="text-[34px] text-black leading-[51px]">
                Contact for <span className="shape-bg">Matter</span>
              </div>
              <div className="mt-6 mb-12">
                Explore bycotted products and contact for inquiries.
              </div>
              <Link to="/contacts" className="btn btn-black">
                Contact Us
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Membership;
