import React from "react";

const Feature = () => {
  return (
    <section className="feature-area bg-[url('../images/reasonsBanner.png')] bg-cover bg-no-repeat bg-center section-padding">
      <div className="container">
        <header className="text-center mb-10">
          <h2 className="mini-title">Why Choose Us</h2>
          <h3 className="column-title">
            Reasons to Support <span className="shape-bg">Build Khalafat</span>
          </h3>
        </header>
        <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-[30px]">
          <article className="feature-item bg-white shadow-box rounded-[8px] p-10 group hover:bg-primary transition duration-150 hover:-translate-y-1">
            <div className="icon-container h-[72px] w-[72px] rounded-full flex items-center justify-center text-secondary bg-green-paste mb-8 text-5xl group-hover:bg-black group-hover:bg-opacity-[0.1] group-hover:text-white transition duration-150">
              <iconify-icon icon="mdi:hand-heart-outline"></iconify-icon>
            </div>
            <h4 className="feature-title lg:text-2xl text-[22px] leading-[30px] mb-4 transition duration-150 group-hover:text-white">
              Non-profit Commitment
            </h4>
            <p className="feature-description transition duration-150 group-hover:text-white">
              We operate as a non-profit organization dedicated to supporting
              Palestinians with transparency and integrity.
            </p>
          </article>

          <article className="feature-item bg-white shadow-box rounded-[8px] p-10 group hover:bg-primary transition duration-150 hover:-translate-y-1">
            <div className="icon-container h-[72px] w-[72px] rounded-full flex items-center justify-center text-secondary bg-green-paste mb-8 text-5xl group-hover:bg-black group-hover:bg-opacity-[0.1] group-hover:text-white transition duration-150">
              <iconify-icon icon="fa-solid:hand-holding-usd"></iconify-icon>
            </div>
            <h4 className="feature-title lg:text-2xl text-[22px] leading-[30px] mb-4 transition duration-150 group-hover:text-white">
              Direct Donation
            </h4>
            <p className="feature-description transition duration-150 group-hover:text-white">
              Your donations go directly to support Palestinian families and
              communities in need, ensuring immediate and effective aid.
            </p>
          </article>

          <article className="feature-item bg-white shadow-box rounded-[8px] p-10 group hover:bg-primary transition duration-150 hover:-translate-y-1">
            <div className="icon-container h-[72px] w-[72px] rounded-full flex items-center justify-center text-secondary bg-green-paste mb-8 text-5xl group-hover:bg-black group-hover:bg-opacity-[0.1] group-hover:text-white transition duration-150">
              <iconify-icon icon="fa-solid:handshake"></iconify-icon>
            </div>
            <h4 className="feature-title lg:text-2xl text-[22px] leading-[30px] mb-4 transition duration-150 group-hover:text-white">
              Ethical Alternatives
            </h4>
            <p className="feature-description transition duration-150 group-hover:text-white">
              Discover ethical products and alternatives that support
              Palestinian communities and avoid contributing to conflicts or
              oppression.
            </p>
          </article>
        </div>
      </div>
    </section>
  );
};

export default Feature;
