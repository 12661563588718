import {
  cause1,
  cause2,
  cause3,
  cause4,
  cause5,
  cause6,
  cause7,
  cause8,
  team1,
  team2,
  team3,
  team4,
  team5,
  team6,
  team7,
  team8,
  team9,
  team10,
  team11,
  team12,
  team13,
  team14,
  lightIcon,
  targetIcon,
  call,
  mail,
  map,
  fbIcon,
  twIcon,
  pnIcon,
  insIcon,
  blog1Img1,
  blog2Img1,
  blog3Img1,
  blog4Img1,
} from "./images";

export const navData = [
  {
    id: 1,
    text: "home",
    url: "/home",
  },
  {
    id: 2,
    text: "about",
    url: "/about",
  },
  {
    id: 3,
    text: "products",
    url: "/products",
  },
  {
    id: 4,
    text: "blogs",
    url: "/blogs",
  },
];

export const mobileMenuData = [
  {
    id: 1,
    text: "home",
    url: "/home",
  },
  {
    id: 2,
    text: "about",
    url: "/about",
  },
  {
    id: 3,
    text: "products",
    url: "/products",
  },
  {
    id: 4,
    text: "Blogs",
    url: "/blogs",
  },
  {
    id: 4,
    text: "contacts",
    url: "/contacts",
  },
];

export const mobileMenuFooterData = [
  {
    id: 1,
    icon: fbIcon,
    url: "https://www.facebook.com/buildkhalafat",
  },
  { id: 2, icon: twIcon, url: "https://twitter.com/buildkhalafat" },
  {
    id: 3,
    icon: pnIcon,
    url: "https://www.pinterest.com/buildkhalafat/",
  },
  {
    id: 4,
    icon: insIcon,
    url: "https://www.instagram.com/buildkhalafat/",
  },
];

export const causesData = [
  {
    id: 1,
    logo: cause1,
    title: "Providing Food Aid",
    description: "Essential food supplies to sustain families in need.",
  },
  {
    id: 2,
    logo: cause2,
    title: "Supporting Orphans",
    description: "Comprehensive care and education for orphaned children.",
  },
  {
    id: 3,
    logo: cause3,
    title: "Medical Assistance",
    description: "Critical healthcare services and medical supplies.",
  },
  {
    id: 4,
    logo: cause4,
    title: "Constructing Masajid",
    description:
      "Building and maintaining places of worship for community gatherings.",
  },
  {
    id: 5,
    logo: cause5,
    title: "Education for All",
    description:
      "Ensuring access to quality education for children and adults.",
  },
  {
    id: 6,
    logo: cause6,
    title: "Disaster Relief",
    description: "Emergency response and support during crises.",
  },
  {
    id: 7,
    logo: cause7,
    title: "Promoting Peace",
    description:
      "Initiatives for conflict resolution and peaceful coexistence.",
  },
  {
    id: 8,
    logo: cause8,
    title: "Sadaqah and Zakat",
    description: "Charitable giving to support those in dire need.",
  },
];

export const helpData = [
  { id: 1, text: "By Prayer", alt: "By Prayer" },
  { id: 2, text: "By Donating", alt: "Support" },
  { id: 3, text: "Become a Volunteer", alt: "Volunteer" },
  { id: 4, text: "Spreading Awareness", alt: "Spread Awareness" },
  {
    id: 5,
    text: "By Boycotting Products of israel",
    alt: "Boycott Products",
  },
];

export const testimonialInfo = [
  {
    id: 1,
    text: "Active Volunteers",
    number: 10,
  },
  {
    id: 2,
    text: "Successful Aid Campaigns",
    number: 5,
  },
  {
    id: 3,
    text: "Community Participation",
    number: 100,
  },
  {
    id: 4,
    text: "Bycotted Products Alternatives",
    number: 100,
  },
];

export const accordionData = [
  {
    id: 1,
    question: "What support does Build Khilafat provide for Palestine?",
    answer:
      "Build Khilafat provides support by donating to Palestinians affected by conflict and advocating for them.",
  },
  {
    id: 2,
    question: "How can I contribute to Build Khilafat?",
    answer:
      "You can contribute by volunteering, donating, or sharing our cause to raise awareness and support for Palestinians in need.",
  },
  {
    id: 3,
    question: "Why choose Build Khilafat for support?",
    answer:
      "Build Khilafat is dedicated to transparency, advocacy, and direct support, ensuring your contributions make a meaningful impact in Palestinian communities.",
  },
  {
    id: 4,
    question: "How does Build Khilafat ensure impact?",
    answer:
      "We measure impact through transparent reporting, testimonials, and ongoing feedback, ensuring accountability and effectiveness in our initiatives.",
  },
  {
    id: 5,
    question: "How can I find alternatives to israeli products?",
    answer:
      "Build Khilafat helps people find alternatives to israeli products.",
  },
];

export const socialLinks = [
  {
    id: 1,
    icon: "bxl:facebook",
    url: "https://www.facebook.com/buildkhalafat",
  },
  { id: 2, icon: "bxl:twitter", url: "https://twitter.com/buildkhalafat" },
  {
    id: 3,
    icon: "bxl:linkedin",
    url: "https://www.linkedin.com/in/buildkhalafat",
  },
  {
    id: 4,
    icon: "bxl:instagram",
    url: "https://www.instagram.com/buildkhalafat/",
  },
];

export const footerLinks = [
  {
    id: 1,
    text: "Home",
    url: "/home",
  },
  {
    id: 2,
    text: "About",
    url: "/about",
  },
  {
    id: 3,
    text: "Products",
    url: "/products",
  },
  {
    id: 5,
    text: "Blogs",
    url: "/blogs",
  },
  {
    id: 4,
    text: "Contact",
    url: "/contacts",
  },
];

export const aboutHeroData = [
  {
    id: 1,
    img: lightIcon,
    title: "Our Mission",
    description:
      "Our mission is to provide humanitarian, medical, and educational support to Palestine.",
  },
  {
    id: 2,
    img: targetIcon,
    title: "Our Vision",
    description:
      "Our vision is a world where Palestine and all Muslims live in dignity, peace, and freedom, with justice and equal opportunities.",
  },
];

export const teamData = [
  {
    img: team1,
    name: "Ahad Ali",
    title: "Founder",
    fb: "https://www.facebook.com/ahad.x.ali",
    linkedin: "https://www.linkedin.com/in/ahadalireach/",
  },
  {
    img: team2,
    name: "Waleed Ahmad",
    title: "Co Founder",
    fb: "https://www.facebook.com/profile.php?id=100010323553154",
    linkedin: "https://www.linkedin.com/in/waleed-ahmad-77758b1b7/",
  },
  {
    img: team3,
    name: "Usama Malook",
    title: "Co Founder",
    fb: "https://www.facebook.com/osamamalook",
    linkedin: "https://www.linkedin.com/in/usamamalook/",
  },
  {
    img: team4,
    name: "Shahan Ali",
    title: "Co Founder",
    fb: "https://www.facebook.com/shahanali.687",
    linkedin: "https://www.linkedin.com/in/shahanali687/",
  },
  {
    img: team5,
    name: "Muhammad Hassan Shakir",
    title: "Volunteer",
    fb: "https://www.facebook.com/shakirofficial92",
    linkedin: "https://www.linkedin.com/",
  },
  {
    img: team6,
    name: "Maaz Ali",
    title: "Volunteer",
    fb: "https://www.facebook.com/Tumkhaasho",
    linkedin: "https://www.linkedin.com/",
  },
  {
    img: team7,
    name: "Kashif Ali",
    title: "Volunteer",
    fb: "https://www.facebook.com/maherkashif.maherkashif.589",
    linkedin: "https://www.linkedin.com/",
  },
  {
    img: team8,
    name: "Asif Dogar",
    title: "Volunteer",
    fb: "https://www.facebook.com/profile.php?id=100040219621955",
    linkedin: "https://www.linkedin.com/",
  },
  {
    img: team9,
    name: "Saad Ali",
    title: "Volunteer",
    fb: "https://www.facebook.com/SayzSaadi",
    linkedin: "https://www.linkedin.com/",
  },
  {
    img: team10,
    name: "Hamza Ashraf",
    title: "Volunteer",
    fb: "https://www.facebook.com/profile.php?id=100040219621955",
    linkedin: "/",
  },
  {
    img: team11,
    name: "Abdullah Shah",
    title: "Volunteer",
    fb: "https://www.facebook.com/profile.php?id=100040219621955",
    linkedin: "https://www.linkedin.com/",
  },
  {
    img: team12,
    name: "Muhammad Basit",
    title: "Volunteer",
    fb: "https://www.facebook.com/basit.jutt.77920",
    linkedin: "https://www.linkedin.com/in/muhammad-basit-nazir-2aba93254/",
  },
  {
    img: team13,
    name: "Sameel Ahmad",
    title: "Volunteer",
    fb: "https://www.facebook.com/profile.php?id=100091860447267",
    linkedin: "https://www.linkedin.com/in/sameel-ahmad-427024258/",
  },
  {
    img: team14,
    name: "Muteeb Haider",
    title: "Volunteer",
    fb: "https://www.facebook.com/profile.php?id=100070855153143",
    linkedin: "https://www.linkedin.com/in/mateeb-haider-233b6b254/",
  },
];

export const blogsData = [
  {
    id: 1,
    title:
      "The Role of Western Countries in Shaping the Current Impact of the Palestine-israel Conflict",
    img1: blog3Img1,
    description1:
      "Explore the influential role of Western countries in shaping the dynamics and impact of the Palestine-israel conflict. This blog critically examines political, economic, and military support, as well as diplomatic policies, that have influenced the region's stability and peace efforts.",
    description2:
      "From historical alliances and interventions to current geopolitical strategies, this piece analyzes the complexities of Western involvement in the Middle East. It aims to provide insights into how external powers have shaped the conflict's trajectory and affected regional dynamics.",
    description3:
      "Through a nuanced perspective, this blog seeks to foster understanding of Western motivations and policies, their impact on local populations, and implications for international relations and peacebuilding efforts in the region.",
    data: [
      {
        id: 1,
        text: "Western countries, particularly the United States, have historically provided significant diplomatic and military support to israel, influencing its military capabilities and geopolitical positioning.",
      },
      {
        id: 2,
        text: "Western interventions and policies, such as arms sales and strategic alliances, have often been criticized for perpetuating conflict and impeding efforts towards a just and lasting peace between israelis and Palestinians.",
      },
      {
        id: 3,
        text: "The role of Western countries in mediating peace negotiations and promoting diplomatic solutions remains crucial, yet their biases and interests can complicate efforts towards a fair resolution of the conflict.",
      },
      {
        id: 4,
        text: "Public opinion and grassroots movements in Western countries have increasingly influenced policies towards the Palestine-israel conflict, advocating for human rights, international law adherence, and equitable peace initiatives.",
      },
    ],
    isLatest: false,
  },
  {
    id: 2,
    title: "Key Events in the History of the Palestine-israel Conflict",
    img1: blog2Img1,
    description1:
      "Explore the significant historical events that have shaped the complex Palestine-israel conflict, from its origins to the present day. This blog provides an in-depth analysis of pivotal moments, examining their impact on political, social, and humanitarian aspects.",
    description2:
      "From the Balfour Declaration of 1917 to the establishment of the State of israel in 1948, delve into the foundational events that set the stage for ongoing tensions and conflicts in the region. This piece aims to provide clarity on key historical narratives often clouded by differing perspectives.",
    description3:
      "Through a chronological examination of wars, peace processes, and diplomatic initiatives, this blog seeks to elucidate the evolving dynamics of the Palestine-israel conflict. By understanding these events, readers can gain insights into the complexities and challenges of achieving lasting peace and justice.",
    data: [
      {
        id: 1,
        text: "The Balfour Declaration of 1917 marked a pivotal moment, where the British government expressed support for the establishment of a 'national home' for the Jewish people in Palestine, significantly influencing the region's demographic and political landscape.",
      },
      {
        id: 2,
        text: "The 1948 Arab-israeli War, following the declaration of the State of israel, resulted in the displacement of hundreds of thousands of Palestinians, leading to enduring refugee crises and shaping subsequent conflicts and negotiations.",
      },
      {
        id: 3,
        text: "The Six-Day War in 1967 saw israel capturing the West Bank, Gaza Strip, and East Jerusalem, territories that have since been a focal point of contention and negotiations in efforts towards a two-state solution.",
      },
      {
        id: 4,
        text: "The Oslo Accords of the 1990s represented a significant attempt at achieving peace, outlining steps towards Palestinian self-governance in parts of the West Bank and Gaza. However, challenges in implementation and continued settlements have hindered lasting peace efforts.",
      },
    ],
    isLatest: true,
  },
  {
    id: 3,
    title: "Understanding the Root Causes of the Palestine-israel Conflict",
    img1: blog1Img1,
    description1:
      "Delve into the complexities and historical context behind one of the most enduring conflicts in modern history—the Palestine-israel conflict. This blog explores the deep-rooted issues, geopolitical dynamics, and humanitarian impacts that continue to shape the region.",
    description2:
      "From the displacement of Palestinians to the ongoing struggles for statehood and self-determination, this piece aims to provide a comprehensive overview, shedding light on perspectives often overshadowed in mainstream discourse.",
    description3:
      "Through a nuanced exploration of historical events and contemporary realities, this blog seeks to foster understanding and empathy, advocating for justice and solidarity in support of Palestine's rights and aspirations.",
    data: [
      {
        id: 1,
        text: "Palestine is home to one of the longest-standing refugee populations globally, with millions displaced since the establishment of israel in 1948.",
      },
      {
        id: 2,
        text: "The United Nations General Assembly has passed numerous resolutions affirming the rights of Palestinians, including their right to self-determination.",
      },
      {
        id: 3,
        text: "The Gaza Strip, a densely populated area under blockade since 2007, faces severe humanitarian crises, including food and water shortages.",
      },
      {
        id: 4,
        text: "Support for Palestine spans the globe, with grassroots movements advocating for boycotts, divestments, and sanctions (BDS) against entities perceived to support israeli occupation.",
      },
    ],
    isLatest: true,
  },
  {
    id: 4,
    title: "Visualising how israel keeps stealing Palestinian land",
    img1: blog4Img1,
    description1:
      "israel has illegally seized more land this year than over the past 20 years combined.",
    description2:
      "In 2024, israel illegally seized 23.7sq km (9.15 sq miles) of Palestinian land in the occupied West Bank, amid its ongoing war on Gaza. That’s more than the land it took over the past 20 years combined.",
    description3:
      "On July 2, israeli authorities announced the largest single seizure in more than 30 years – 12.7sq km (4.9sq miles) in the Jordan Valley. It was the latest in a series of land grabs announced this year by Israel’s far-right Finance Minister Bezalel Smotrich, who oversees settlement planning.",
    data: [
      {
        id: 1,
        text: "israel has seized more than 50sq km (19.3sq miles) of Palestinian land since 1998 according to Peace Now, an israeli anti-settlement watchdog.",
      },
      {
        id: 2,
        text: "During World War I, Britain made agreements to gain the support of various groups in the Middle East, most notable was the 1917 Balfour Declaration.",
      },
      {
        id: 3,
        text: "On May 14, 1948, the British Mandate expired and Zionist leaders announced they would be declaring a state, triggering the first Arab-Israeli war.",
      },
      {
        id: 4,
        text: "During the June 1967 war, israel occupied all of historical Palestine – including Gaza, the West Bank and East Jerusalem – the Syrian Golan Heights and Egypt’s Sinai Peninsula.",
      },
      {
        id: 5,
        text: "In 2002, israel began constructing a wall that snakes more than 700km (435 miles) through the West Bank, dividing villages, encircling towns and splitting families from each other.",
      },
      {
        id: 6,
        text: "About 700,000 settlers live in some 300 illegal settlements and outposts dotting the occupied West Bank and East Jerusalem.",
      },
    ],
    isLatest: true,
  },
];

export const productsProgressBar = [
  {
    id: 1,
    label: "Ethical Consumerism",
    percentage: 86,
    colorClass: "bg-primary",
  },
  {
    id: 2,
    label: "Humanitarian Support",
    percentage: 77,
    colorClass: "bg-secondary",
  },
  {
    id: 3,
    label: "Advocacy & Awareness",
    percentage: 95,
    colorClass: "bg-tertiary",
  },
];

export const products = [
  {
    id: 1,
    bycotted: "KFC",
    pakistani: "Cheezious",
    international: "Albaik",
    category: "restaurant",
  },
  {
    id: 2,
    bycotted: "McDonalds",
    pakistani: "Burger Lab",
    international: "Nando's",
    category: "restaurant",
  },
  {
    id: 3,
    bycotted: "PizzaHut",
    pakistani: "Butt Karahi",
    international: "Zaatar w Zeit",
    category: "restaurant",
  },
  {
    id: 4,
    bycotted: "Subway",
    pakistani: "Ranchers",
    international: "Just Falafel",
    category: "restaurant",
  },
  {
    id: 5,
    bycotted: "Burger King",
    pakistani: "Smash Burger",
    international: "Comptoir Libanais",
    category: "restaurant",
  },
  {
    id: 6,
    bycotted: "Baskin & Robbins",
    pakistani: "Mr. Burger",
    international: "Maoz Vegetarian",
    category: "restaurant",
  },
  {
    id: 7,
    bycotted: "Hardees",
    pakistani: "Optp",
    international: "Maroush",
    category: "restaurant",
  },

  // Cold Drinks
  {
    id: 8,
    bycotted: "Coca Cola / Pepsi",
    pakistani: "Cola Next / Rango Next",
    international: "Zamzam Cola",
    category: "cold drink",
  },
  {
    id: 9,
    bycotted: "7-UP / Sprite / Mountain Dew",
    pakistani: "Pakola / Fizz Up",
    international: "Qibla Cola",
    category: "cold drink",
  },
  {
    id: 10,
    bycotted: "Red Bull / Sting",
    pakistani: "Big Apple / Big Lychee",
    international: "Viva Palestina",
    category: "cold drink",
  },
  {
    id: 11,
    bycotted: "Fanta / Mirinda",
    pakistani: "Gourmet",
    international: "Jarritos",
    category: "cold drink",
  },

  // Water
  {
    id: 12,
    bycotted: "Nestle",
    pakistani: "Gourmet",
    international: "Qibla Water",
    category: "water",
  },
  {
    id: 13,
    bycotted: "Aquafina",
    pakistani: "Sufi",
    international: "Fiji Water / Palestine Spring Water",
    category: "water",
  },
  {
    id: 14,
    bycotted: "Dasani",
    pakistani: "Pakola / Masafi",
    international: "Volvic / Safi Water",
    category: "water",
  },

  // Chocolates
  {
    id: 15,
    bycotted: "Cadbury",
    pakistani: "You",
    international: "Al-Nassma Chocolate",
    category: "chocolate",
  },
  {
    id: 16,
    bycotted: "Nestle",
    pakistani: "Now",
    international: "Patchi",
    category: "chocolate",
  },
  {
    id: 17,
    bycotted: "Hershey’s",
    pakistani: "Paradise",
    international: "Güven Chocolate",
    category: "chocolate",
  },
  {
    id: 18,
    bycotted: "Mars",
    pakistani: "Sonnet",
    international: "Elvan Chocolate",
    category: "chocolate",
  },
  {
    id: 19,
    bycotted: "Snickers",
    pakistani: "Novella / Cloud 9",
    international: "Anwar Al-Chocolate",
    category: "chocolate",
  },

  // Cosmetics
  {
    id: 20,
    bycotted: "L'Oreal",
    pakistani: "J.",
    international: "Annemarie Börlind",
    category: "cosmetics",
  },
  {
    id: 21,
    bycotted: "Garnier",
    pakistani: "Saeed Ghani",
    international: "Bio Damascena by Alteya Organics",
    category: "cosmetics",
  },
  {
    id: 22,
    bycotted: "Estee Lauder / Revlon",
    pakistani: "Medora / Nabeela",
    international: "Harem's Ottoman",
    category: "cosmetics",
  },
  {
    id: 23,
    bycotted: "Olay",
    pakistani: "Rivaj / Sweet touch",
    international: "Farmasi",
    category: "cosmetics",
  },

  // Cream
  {
    id: 24,
    bycotted: "Nivea",
    pakistani: "Golden Pearl / Joy & Lovely",
    international: "Missha",
    category: "cream",
  },
  {
    id: 97,
    bycotted: "Fair & Lovely",
    pakistani: "Tibet / Stillmans / Nisa",
    international: "----",
    category: "cream",
  },

  // Ice Cream
  {
    id: 25,
    bycotted: "Walls",
    pakistani: "Hico / Lush Crush / Chaman Ice Cream",
    international: "Gelato Italiano",
    category: "ice cream",
  },

  {
    id: 26,
    bycotted: "Omore",
    pakistani: "Hico / United King / Jalal Sons",
    international: "Mado",
    category: "ice cream",
  },
  {
    id: 96,
    bycotted: "----",
    pakistani: "Zam Zam / Rehmat e Sheeren",
    international: "Dondurma ",
    category: "ice cream",
  },

  // Tea & Coffee
  {
    id: 27,
    bycotted: "Lipton",
    pakistani: "Vital / Danedar",
    international: "Dilmah",
    category: "tea & coffee",
  },
  {
    id: 28,
    bycotted: "Nescafe",
    pakistani: "Tapal / Imtiaz Coffee",
    international: "Lavazza / Ahmad Tea",
    category: "tea & coffee",
  },

  // Juice
  {
    id: 29,
    bycotted: "Nestle Fruita Vitals / Shezan",
    pakistani: "Fresher / Popular / Fruitien",
    international: "Al Rawabi / Rooh Afza",
    category: "juice",
  },
  {
    id: 30,
    bycotted: "Nestle Nesfruita / Slice / Energile",
    pakistani: "Fruitien / Maza / Tropico",
    international: "Al Marai / Tanuf",
    category: "juice",
  },
  {
    id: 31,
    bycotted: "Tang",
    pakistani: "Maza / Jami Shireen / Vivo",
    international: "Al Ain Dairy / Al Rabie",
    category: "juice",
  },

  // Flavoured Drinks
  {
    id: 32,
    bycotted: "Milo / Nescafe",
    pakistani: "Dayfresh / Olpers",
    international: "Frutti Extra Bursa / WOW",
    category: "flavoured drink",
  },
  {
    id: 33,
    bycotted: "Nesquik",
    pakistani: "Shakarganj / Pakola",
    international: "Al Waha / Qoo",
    category: "flavoured drink",
  },

  // Nimcos
  {
    id: 34,
    bycotted: "4U",
    pakistani: "Shahi / Kababjees / Super Crisp / Namkeen / Lahori",
    international: "Al Rifai Roastery / Al Bital",
    category: "nimco",
  },

  // Dairy
  {
    id: 35,
    bycotted: "Happy Cow / President",
    pakistani: "Anhaar / Gourmet Yogurt / Adam’s / Peak Fresh",
    international: "Nada Dairy / Al Safi Danone",
    category: "dairy",
  },
  {
    id: 36,
    bycotted: "Philadelphia / Arla",
    pakistani: "Fresh Pure / Khadam / Neeli bar / Nurpur",
    international: "Luna",
    category: "dairy",
  },
  {
    id: 95,
    bycotted: "Anchor / Castello / Trestelie",
    pakistani: "Fresh Pure / Khadam / Neeli bar / Nurpur",
    international: "Almarai",
    category: "dairy",
  },
  {
    id: 37,
    bycotted: "Nesvita / Puck / Kraft",
    pakistani: "Haleeb / Tarang",
    international: "Marmum / Pinar",
    category: "dairy",
  },

  // Cereals
  {
    id: 38,
    bycotted: "Nestle Cococrunch / Weetabix",
    pakistani: "Nutri Lov Crunchy / Nature’s Home",
    international: "Al Baker",
    category: "cereal",
  },
  {
    id: 39,
    bycotted: "Kelloggs",
    pakistani: "Fauji Cornflakes / Mico",
    international: "Al Raya",
    category: "cereal",
  },

  // Snacks
  {
    id: 40,
    bycotted: "Lays",
    pakistani: "Super Crisp",
    international: "Al Rifai",
    category: "snack",
  },
  {
    id: 41,
    bycotted: "Cheetos",
    pakistani: "Kurleez",
    international: "Haldiram's / Al Ain Dairy",
    category: "snack",
  },
  {
    id: 42,
    bycotted: "Kurkure",
    pakistani: "Oye Hoye",
    international: "Al Mudhish",
    category: "snack",
  },
  {
    id: 43,
    bycotted: "Pringles",
    pakistani: "Knock Out / Kolson",
    international: "Al Watania / Pinar Chips",
    category: "snack",
  },

  // Biscuits
  {
    id: 44,
    bycotted: "LU / Oreo",
    pakistani: "Bisconni / Innovative / Whistlez",
    international: "Al Rifai",
    category: "biscuit",
  },
  {
    id: 94,
    bycotted: "Kinder Joy",
    pakistani: "Rite / Peak Freans / Hilal / Mayfair / Cafe",
    international: "Ulker / Crown",
    category: "biscuit",
  },

  // Noodles & Pasta
  {
    id: 45,
    bycotted: "Maggi / Ragu",
    pakistani: "Kolson Pasta / National Pasta",
    international: "Sadaf / Al Mudhish",
    category: "noodles & pasta",
  },
  {
    id: 46,
    bycotted: "Knorr / Blazin",
    pakistani: "Shoop By Shan / Bake Palor / Crispo",
    international: "Barilla",
    category: "noodles & pasta",
  },

  // Shampoo
  {
    id: 47,
    bycotted: "Dove / Vatika / Head & Shoulders",
    pakistani: "Saeed Chani",
    international: "Elidor's",
    category: "shampoo",
  },
  {
    id: 48,
    bycotted: "Sunsilk / Lifebuoy / Clear",
    pakistani: "Co Natural / Samsol",
    international: "Hask / Herbal Essences",
    category: "shampoo",
  },
  {
    id: 49,
    bycotted: "Pantene / Dove",
    pakistani: "Bio Amla",
    international: "Himalaya Herbals",
    category: "shampoo",
  },

  // Toothpaste
  {
    id: 50,
    bycotted: "Colgate / Close Up",
    pakistani: "Medicam / Miswak",
    international: "Siwak Al-Khair",
    category: "toothpaste",
  },
  {
    id: 51,
    bycotted: "Sensodyne",
    pakistani: "Dentonic / Jasmine Organics / Dentonic",
    international: "Hello / Misin ",
    category: "toothpaste",
  },

  // Cooking Oil
  {
    id: 52,
    bycotted: "Dalda",
    pakistani: "Sufi / Kisan / Meezan / Andaaz",
    international: "Al Waha / Aytac",
    category: "cooking oil",
  },
  {
    id: 53,
    bycotted: "Rafan",
    pakistani: "Habib / Soya Supreme / barkat",
    international: "Al Khair / Al Afia / Al Taj",
    category: "toothpaste",
  },

  // Jam Jelly
  {
    id: 54,
    bycotted: "Rafhan",
    pakistani: "National, Salman's, Kissan",
    international: "Al Areesh / Al Rabih",
    category: "jam jelly",
  },

  // Butter
  {
    id: 55,
    bycotted: "Nestle / Blue Bank",
    pakistani: "Nurpur / Mumtaz / Almarai",
    international: "Anchor / Al Marai",
    category: "butter",
  },
  {
    id: 56,
    bycotted: "Lurpak / Kraft",
    pakistani: "Deen’s / Fresh Pure / Neeli bar",
    international: "Nabil / Bateel",
    category: "butter",
  },
  {
    id: 57,
    bycotted: "Anchor / Olive",
    pakistani: "Khadam / Achha Butter / Millac / Nawal",
    international: "Aytac / Pinar",
    category: "butter",
  },

  // Tissue
  {
    id: 58,
    bycotted: "Fay",
    pakistani: "Rose Petal, Hankies",
    international: "Fine / Softis",
    category: "tissue",
  },

  // Batteries
  {
    id: 59,
    bycotted: "-",
    pakistani: "Osaka / Excide",
    international: "Sakthi Automotive Group",
    category: "battery",
  },
  {
    id: 93,
    bycotted: "-",
    pakistani: "Phoenix / Atlas / National",
    international: "Al Muqarram Group",
    category: "battery",
  },

  // Insect Killer
  {
    id: 60,
    bycotted: "Mortein / Power Plus",
    pakistani: "Kingtox / Tyfon",
    international: "Al-Bait Al-Mumayez / Baygon",
    category: "insect killer",
  },

  // Shoe Polish
  {
    id: 61,
    bycotted: "KIWI / Cherry Blossom",
    pakistani: "WBM Shoe Care",
    international: "Karakalem  / Al Wazir",
    category: "shoe polish",
  },

  // Candies
  {
    id: 62,
    bycotted: "Cadbury",
    pakistani: "Hilal / Candyland",
    international: "Patchi",
    category: "candy",
  },
  {
    id: 92,
    bycotted: "Nestle / Mentos",
    pakistani: "Mayfair / Giggly",
    international: "Alrifai",
    category: "candy",
  },

  // Baby Care
  {
    id: 63,
    bycotted: "Johnson's",
    pakistani: "Mothercare . Moms Touch",
    international: "Himalaya BabyCare",
    category: "baby care",
  },
  {
    id: 91,
    bycotted: "Cane babe",
    pakistani: "Bona papa",
    international: "----",
    category: "baby care",
  },
  {
    id: 64,
    bycotted: "Pampers / Huggies",
    pakistani: "Soft Touch / Nexton",
    international: "Fine Baby",
    category: "baby care",
  },
  {
    id: 65,
    bycotted: "Canebabe",
    pakistani: "Shield",
    international: "Farlin ",
    category: "baby care",
  },

  // soap
  {
    id: 66,
    bycotted: "Lux / Palmolive",
    pakistani: "Tibet / Capri",
    international: "Duru",
    category: "soap",
  },
  {
    id: 67,
    bycotted: "Lifebuoy / Safeguard",
    pakistani: "Vital / Hemani",
    international: "Gulsha / Olivos ",
    category: "soap",
  },
  {
    id: 90,
    bycotted: "Dettol",
    pakistani: "Sufi / Nisa",
    international: "Eva",
    category: "soap",
  },

  // Hair Remover
  {
    id: 68,
    bycotted: "Veet",
    pakistani: "EU Cream / Olivia / Saeed Ghani",
    international: "Flormar Wax Strips",
    category: "hair remover",
  },
  {
    id: 89,
    bycotted: "Gillette",
    pakistani: "Treet / Samsol / Tibet / Medicam / Nisa",
    international: "Alcos",
    category: "hair remover",
  },

  //face care
  {
    id: 69,
    bycotted: "Ponds",
    pakistani: "Hiba / Saeed Ghani",
    international: "Beesline",
    category: "face care",
  },
  {
    id: 88,
    bycotted: "Fair & Lovely",
    pakistani: "YC face washes / Hemani / Stillmans",
    international: "Herbline Essentials",
    category: "face care",
  },
  {
    id: 70,
    bycotted: "Vaseline",
    pakistani: "Tibet Orange Blu Seal",
    international: "Al Madina / Dalan",
    category: "face care",
  },

  // Perfumes
  {
    id: 71,
    bycotted: "Denim Fragrances",
    pakistani: "Hemani / J. / Saeed Ghani",
    international: "Rasasi",
    category: "fragrance",
  },
  {
    id: 87,
    bycotted: "Victoria’s Secret / AXE",
    pakistani: "Bonanza Satrangi / Stylo / Khaadi",
    international: "Abdul Samad Al Qurashi",
    category: "fragrance",
  },

  // Detergents
  {
    id: 72,
    bycotted: "Vim",
    pakistani: "Sufi / Saffon",
    international: "Fairy",
    category: "detergent",
  },
  {
    id: 86,
    bycotted: "Max",
    pakistani: "Chase Up Liquid",
    international: "Sana / Lana",
    category: "detergent",
  },
  {
    id: 73,
    bycotted: "Bonus / Brite / Surf",
    pakistani: "AIclean",
    international: "Clorox ",
    category: "detergent",
  },
  {
    id: 74,
    bycotted: "Excel / Arial / Rin",
    pakistani: "Almas Dishwasher",
    international: "Biobel",
    category: "detergent",
  },
  {
    id: 75,
    bycotted: "Express Power / Tide",
    pakistani: "Lemon Max",
    international: "Bingo",
    category: "detergent",
  },

  // Toilet cleaner
  {
    id: 76,
    bycotted: "Harpic / Domex",
    pakistani: "AlClean (Toilet Bowl Cleaner)",
    international: "Dac",
    category: "toilet cleaner",
  },
  {
    id: 85,
    bycotted: "Lysol / Clorox",
    pakistani: "Aquila",
    international: "----",
    category: "toilet cleaner",
  },

  // Body Lotion
  {
    id: 77,
    bycotted: "Mother Care / Revlon / Ponds",
    pakistani: "Care / Tibet",
    international: "Nefertari",
    category: "body lotion",
  },
  {
    id: 84,
    bycotted: "Revlon / Ponds",
    pakistani: "Saeed Ghani",
    international: "Swiss Arabian / Lootah",
    category: "body lotion",
  },

  // Shoes & Bags
  {
    id: 78,
    bycotted: "Nike / Calvin Klein / Gucci",
    pakistani: "Service / J.",
    international: "Arabian Oud",
    category: "shoes & bag",
  },
  {
    id: 82,
    bycotted: "Reebok / Levis / Amani",
    pakistani: "Stylo",
    international: "Mounir",
    category: "shoes & bag",
  },
  {
    id: 83,
    bycotted: "Adidas / Boss / Bata / INDURE",
    pakistani: "Gul Ahmed",
    international: "Mounir",
    category: "shoes & bag",
  },

  // Electronics
  {
    id: 79,
    bycotted: "Sony",
    pakistani: "Dawlance / Waves / West Point",
    international: "Arçelik (Beko) / Ooredoo / Haier",
    category: "electronics",
  },

  // Petrol Pumps
  {
    id: 80,
    bycotted: "Shell",
    pakistani: "PSO",
    international: "Saudi Aramco / ADNOC",
    category: "petrol pumps",
  },
  {
    id: 81,
    bycotted: "Total Parco",
    pakistani: "Hascol",
    international: "Kuwait Petroleum Corporation",
    category: "petrol pumps",
  },
];

export const contactUsData = [
  {
    id: 1,
    img: mail,
    label: "Email-Us :",
    text: "buildkhalafat@gmail.com",
  },
  {
    id: 2,
    img: call,
    label: "Call-Us :",
    text: "+923241441444",
  },
  {
    id: 3,
    img: map,
    label: "Visit-Us :",
    text: "Lahore Punjab, Pakistan",
  },
];
