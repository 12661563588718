import React from "react";
import { Link } from "react-router-dom";
import { Header, Footer } from "../Components";

const Thanks = () => {
  return (
    <>
      <Header />
      <div className="min-h-screen bg-[url('../images/thank-you-bg.png')] bg-cover bg-no-repeat bg-center flex items-center justify-center">
        <div className="max-w-[570px] mx-auto flex flex-col items-center text-center px-6 py-12 bg-white bg-opacity-90 rounded-lg">
          <h1 className="text-4xl font-bold text-gray-800 mb-4">Thank You!</h1>
          <h3 className="text-xl text-gray-600 mb-4">
            For Getting In Touch. We will contact you very soon.
          </h3>
          <Link to="/" className="btn btn-primary">
            Back to Home
          </Link>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Thanks;
