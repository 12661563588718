import React, { useEffect } from "react";
import {
  AboutHero,
  HomeHero,
  Trusted,
  Reasons,
  Footer,
  Header,
  NewsLetter,
  Accordion,
  Testimonials,
  Causes,
  SupportLinks,
  HowToHelp,
  HomeBlogs,
} from "../Components";

const Home = () => {
  useEffect(() => {
    document.documentElement.scrollTop = 0;
  }, []);
  return (
    <>
      <Header />
      <HomeHero />
      <AboutHero />
      <Trusted section_padding_bottom={"section-padding-bottom"} />
      <Reasons />
      <Causes />
      <HowToHelp />
      <SupportLinks />
      <HomeBlogs />
      <NewsLetter />
      <Testimonials />
      <Accordion />
      <Footer />
    </>
  );
};

export default Home;
